import { getInlineBackgroundColor, getInlineColor } from "../elements/progressBar";
import { Tooltip } from "antd";
import './style.css'
import { useEffect, useRef } from "react";

interface IBuildSentence{
    sentence: string;
    backgroundColor: string;
    color: string;
    score: number;
}

const BuildSentence: React.FC<IBuildSentence> = (props) => {
    const {sentence, score} = props;
    const containerRef = useRef(null);

    return <span style={{
        }}
            ref={containerRef}
            className={"tooltip__parent " + (score < 40 ? 'tooltip__parent-green' : score < 60 ? 'tooltip__parent-yellow' : 'tooltip__parent-red')}
            dangerouslySetInnerHTML={{__html: sentence}}
        />
}

interface IBuildSegments {
    data: {text: string, backgroundColor: string, color: string, score: number}[]
}

export const BuildSegments: React.FC<IBuildSegments> = (props) => {
    const {data} = props;
    return <>
        {
            data.map(
                (e) => <BuildSentence 
                    sentence={e.text}
                    backgroundColor={e.backgroundColor}
                    score={e.score}
                    color={e.color}
                />
            )
        }
    </>
}

function summarizeScores(text: string, segments: number[]) {
    var sentences = []
    var splitted = text.replaceAll('. ', '|||').split(' ');
    if (splitted.length === 1) {
        return {sentenceScores: [segments.reduce((p, n) => p + n) / segments.length], sentences: []}
    }
    var sentenceScores: number[] = []
    var sum = 0;
    var len = 0;
    var sentence = "";
    for (var i = 0; i < Math.min(splitted.length, segments.length); ++i) {
        var e = splitted[i];
        if (e.indexOf('|||') !== -1 || e.indexOf('.\n') !== -1) {
            sentenceScores.push(sum / len);
            sum = 0;
            len = 0;
            sentences.push(sentence);
        }
        sum += segments[i];
        sentence += e;
        len += 1;
        
    }
    if (len !== 0) {
        sentenceScores.push(sum / len);
        sentences.push(sentence)
    }
    return {sentenceScores, sentences};
}

export function buildSentenceTooltipStyle(active: boolean) {
    return `display: ${active ? "inline-flex" : "none"};
    position: absolute;
    background-size: 102px 36px;
    width: 102px;
    height: 36px;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    transform: translateY(-8px);
    user-select: none;
    `
}

export function mapSegments(text: string, segments: number[]) {
    if (text.endsWith('.')) {
        text = text.slice(0, text.length-1);
    }
    var splitted = text.replaceAll('\n', '<br>').split(/\./);
    var {sentenceScores, sentences} = summarizeScores(text, segments);

    const validScores = sentenceScores.filter(score => !Number.isNaN(score));
    const avg = validScores.length > 0 
        ? validScores.reduce((e, v) => e + v) / validScores.length 
        : 50;

    return splitted.map((e, index) => 
        {
            var r = sentenceScores[index] * 100;
            if (Number.isNaN(r)) {
                r = avg * 100;
            }
            var shouldAddDot = index < splitted.length - 1;
            return {
                'text': `${e}${shouldAddDot ? '.' : ''}
                    <div class="div__tooltip">
                        <div
                            contentEditable="false"
                            style="
                                ${buildSentenceTooltipStyle(false)}
                            " class="segmentation-tooltip ${r < 40 ? "good__tooltip" : r < 60 ? "medium__tooltip" : "bad__tooltip"}">${r.toFixed(2)}% AI
                        </div>
                    </div>
                    
                `, 
                'backgroundColor': getInlineColor(r),
                'color': getInlineBackgroundColor(r),
                'score': r
            }
        })
}