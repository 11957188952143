import react, { useEffect, useState } from 'react'
import { Sidebar } from '../../../elements/sidebar'
import './styles.css'
import { FileDrop } from '../../../elements/fileDrop'
import { CurrentPlan } from '../../../elements/currentPlan'
import { Chart } from '../../../elements/line'
import { IRow, Table } from '../../../elements/table'
import { Button, Divider, Modal } from 'antd'
import { createAPIKey, deleteAPIKey, getAnalytics, getApiKeys, updateAPIKey } from '../../../requests'
import { CopyIcon } from '../../../elements/icons'
import { Selector } from '../../../elements/selector'
import { CopyToClipboardIcon } from '../../../elements/copyToClipboard'
import {logEvent} from "../../../utils";

export const Layout: react.FC = () => {
    return <div className='layout__container centered-layout'>
        <div className="widd">
            <span className="header">Text scan</span>
            <div className="file__screen">
                <FileDrop />
            </div>
        </div>
        
    </div>
}

export const APIUsage: react.FC = () => {
    const [chartData, setChartData] = useState([]);
    const [trashold, setTreshold] = useState(7);

    useEffect(() => {
        getAnalytics(trashold).then((e) => {
            setChartData(e);
        })
    }, [trashold]);

    return (
        <div className='layout__container'>
            <div className="header">Usage</div>
            <div className="file__screen">
                <CurrentPlan />
            </div>
            <div className="header__container stats">
                <div className="header">Statistics</div>
                <Selector onSelect={(tr) => {
                    setTreshold(tr);
                }}/>
            </div>
            <div className="charts">
                <div className="chart-row">
                    <div className="chart-with-header">
                        <div className="chart-with-header__header">Scan</div>
                        <Chart 
                            header='Words, per day'
                            chartData={[{
                                id: 'Simple scan',
                                data: chartData.map((e: any) => ({
                                    y: e.text,
                                    x: e.time
                                }))
                            },
                            {
                                id: 'Deep scan',
                                data: chartData.map((e: any) => ({
                                    y: e.deep_scan_words,
                                    x: e.time
                                }))
                            }]}
                        />
                    </div>
                    <div className="chart-with-header">
                        <div className="chart-with-header__header">Britishizer</div>
                        <Chart 
                            header='Words, per day'
                            chartData={[{
                                id: 'Britishizer',
                                data: chartData.map((e: any) => ({
                                    y: e.british_words,
                                    x: e.time
                                }))
                            }]}
                        />
                    </div>
                </div>
                <div className="chart-row">
                    <div className="chart-with-header">
                        <div className="chart-with-header__header silent">Scan1</div>
                        <Chart
                            header='Queries, per day'
                            chartData={[{
                                id: 'Scan',
                                data: chartData.map((e: any) => ({
                                    y: e.queries,
                                    x: e.time
                                }))
                            }]}
                        />
                    </div>
                    <div className="chart-with-header">
                        <div className="chart-with-header__header silent">Britishizer1</div>
                        <Chart 
                            header='Queries, per day'
                            chartData={[{
                                id: 'Britishizer',
                                data: chartData.map((e: any) => ({
                                    y: e.british_queries,
                                    x: e.time
                                }))
                            }]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

interface ITableModal {
    isModalOpen: boolean;
    setIsModalOpen: (state: boolean) => void;
    onClick: (state: any) => void;
}
interface ICreateAPIKeyModal extends ITableModal {
    onClick: (state: any) => Promise<string>;
    updateStates: () => void;
}
enum MODALS {
    CREATE_MODAL,
    AFTER_CREATE_MODAL,
    CHANGE_MODAL,
    DELETE_MODAL,
    NONE
}

const CreateAPIKeyModal: react.FC<ICreateAPIKeyModal> = (props) => {
    const {isModalOpen, setIsModalOpen} = props;
    const [text, setText] = useState('');
    const [id, setId] = useState('');
    const [stage, setStage] = useState(0);

    const stages = [
        <>
            <div className='modal__container'>
                <input 
                    type="text" 
                    placeholder='My new key (optional)'
                    onChange={(e) => {
                        setText(e.target.value);
                    }}
                />
            </div>
        </>,
        <>
            <div className='modal__text-container'>
                <span className='modal__text fz16'>Do not share the key with anyone. Now you can copy your API key.</span>
                <div className="api-key_text__container">
                    <span className="api-key__text">{id}</span>
                    <CopyToClipboardIcon textToCopy={id}/>
                </div>
            </div>
        </>
    ];
    const titles = ['New API Key', 'Key created']
    const footers = [
        <div className='footer__btns'>
                <button className='primary secondary' onClick={() => {
                    setIsModalOpen(false);
                }}>Cancel</button>
                <button
                    className='primary'
                    onClick={() => {
                        props.onClick(text).then((e) => {
                            setId(e);
                            setStage(1);
                        });
                    }}
                >Create key</button>
        </div>,
        <div className='footer__btns'>
        <button className='primary secondary' onClick={() => {
                setIsModalOpen(false);
                setStage(0);
                props.updateStates();
            }}>OK</button>
        </div>
    ]
    return <Modal
        title={titles[stage]}
        open={isModalOpen}
        footer={[
            footers[stage]
        ]}
    >
        {stages[stage]}
    </Modal>
}
interface IDeleteAPIModal extends ITableModal{
    title: string
}
export const DeleteAPIKeyModal: react.FC<IDeleteAPIModal> = (props) => {
    const {isModalOpen, setIsModalOpen} = props;
    return <Modal 
        title='Delete API Key'
        open={isModalOpen}
        footer={[
            <div className='footer__btns'>
                <button className='primary secondary' onClick={() => {
                    setIsModalOpen(false);
                }}>Cancel</button>
                <button 
                    className='primary'
                    onClick={() => {
                        props.onClick("");
                    }}
                >Delete</button>
            </div>
        ]}
    >
        <div className='modal__text-container'>
            <span className='modal__text'>Are you sure to delete the key <strong>{props.title}</strong>?</span>
        </div>
    </Modal>
}

interface IChangeAPIModal extends ITableModal {
    title: string;
    setTitle: (title: string) => void;
}
export const ChangeAPIModal: react.FC<IChangeAPIModal> = (props) => {
    const {isModalOpen, setIsModalOpen} = props;
    return <Modal
        title='New API Key'
        open={isModalOpen}
        footer={[
            <div className='footer__btns'>
                <button className='primary secondary' onClick={() => {
                    setIsModalOpen(false);
                }}>Cancel</button>
                <button 
                    className='primary'
                    onClick={() => {
                        props.onClick("");
                    }}
                >Change key</button>
            </div>
        ]}
    >
        <div className='modal__container'>
            <input 
                type="text" 
                placeholder='My new key (optional)'
                onChange={(e) => {
                    props.setTitle(e.target.value);
                }}
                value={props.title}
            />
        </div>
    </Modal>
}

export const APITable: react.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tableData, setTableData] = useState<IRow[]>([]);
    const [reloadTableData, setReloadTableData] = useState(false);
    const [activeModal, setActiveModal] = useState(MODALS.NONE);
    const [activeKey, setActiveKey] = useState('');
    const [activeName, setActiveName] = useState('');

    useEffect(() => {
        getApiKeys().then((e) => {
            setTableData(e.filter((ee: any) => ee));
        });
    }, [reloadTableData]);

    var title = '';
    try {
        title = tableData.filter(e => e.apikey == activeKey)[0].name;
    } catch {
        
    }
    
    return <div className='layout__container'>
        {
            activeModal == MODALS.NONE ?
            <></> :
            activeModal == MODALS.CREATE_MODAL ?
            <CreateAPIKeyModal 
                isModalOpen={isModalOpen} 
                setIsModalOpen={setIsModalOpen}
                onClick={async (e) => {
                    logEvent("Create api key click")
                    const ee = await createAPIKey(e);
                    return ee.apikey;
                }}
                updateStates={() => {
                    setReloadTableData(!reloadTableData);
                }}
            /> :
            activeModal == MODALS.DELETE_MODAL ?
            <DeleteAPIKeyModal 
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClick={(e: string) => {
                    logEvent("Delete api key click")
                    deleteAPIKey(activeKey).then((ee) => {
                        setIsModalOpen(false)
                        setReloadTableData(!reloadTableData);
                    })
                }}
                title={title}
            /> :
            activeModal == MODALS.CHANGE_MODAL ?
            <ChangeAPIModal 
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClick={async (e: string) => {
                    updateAPIKey(activeKey, activeName).then((e) => {
                        setIsModalOpen(false);
                        setReloadTableData(!reloadTableData);
                    })
                }}
                title={activeName}
                setTitle={(title: string) => {
                    setActiveName(title);
                }}
            /> : <></>
        }
        <div className="header">
            API keys
        </div>

        <div className="file__screen">
            <Table 
                data={tableData} 
                onChange={(key: string) => {
                    setActiveKey(key);
                    setActiveName(tableData.filter((e) => e.apikey == key)[0].name);
                    setActiveModal(MODALS.CHANGE_MODAL);
                    setIsModalOpen(true)
                }} 
                onDelete={(key: string) => {
                    setActiveKey(key);
                    setActiveModal(MODALS.DELETE_MODAL);
                    setIsModalOpen(true);
                }}
            />
            <div className="create-new-key">
                <button className='primary create-new-key' onClick={() => {
                    setIsModalOpen(true);
                    setActiveModal(MODALS.CREATE_MODAL);
                }}>+ Create new key</button>
            </div>
        </div>
    </div>
}