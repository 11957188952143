import React from 'react';
import { Logo } from '../../elements/icons';

import './style.css';

interface MobileScreenProps {
  // Add any props here if needed
}

const MobileScreen: React.FC<MobileScreenProps> = () => {
  return (
    <div className="mobile-screen">
      <div className="logo__container">
        <Logo />
      </div>
      <div className="mobile-screen__content">
        <span className="mobile-screen__content-header">
          It’s AI app is not available 
          for mobile devices
        </span>
        <span className="mobile-screen__content-text">
          You can access It’s AI app only from desktop devices
        </span>
        <img src="/mobile-screen.png" alt="" width={270} className='mobile-screen__content-image' />
        <button className='primary' style={{width: '100%'}} onClick={() => {
          window.location.href = 'https://its-ai.org';
        }}>Go to Website</button>
      </div>
    </div>
  );
};

export default MobileScreen;
