import { ResponsiveLine } from "@nivo/line";
import react, { useEffect, useState } from 'react';
import './style.css'
import 'react-tooltip/dist/react-tooltip.css'
import { TooltipIcon } from "../icons";


interface IChartData {
    id: string;
    data: {
        x: string,
        y: number
    }[];
}

interface IChart {
    header: string;
    chartData: IChartData[];
}

export const Chart: react.FC<IChart> = (props) => {
    const height = 290;
    const [width, setWidth] = useState(300);

    const gradProps = {
        gradientUnits: 'userSpaceOnUse',
        x1: '0',
        y1: '0',
        x2: '0',
        y2: height
    };

    useEffect(() => {
        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        setWidth((vw - 320 - 80) / 2 - 30);
    }, []);

    const isDataEmpty = props.chartData.every(dataset => 
        dataset.data.every(point => point.y === 0)
    );

    return <div className="chart__container">
        <div className="chart__header">
            {props.header}
        </div>
        <div style={{ height, width }}>
            <svg style={{height: 0, width: 0}}>
                <defs>
                    <linearGradient id="gradient1" {...gradProps} >
                        <stop offset="25%" stopColor="var(--primary-color)" />
                        <stop offset="100%" stopColor="rgba(255,255,255,0)" />
                    </linearGradient>
                    <linearGradient id="gradient2" {...gradProps} >
                        <stop offset="25%" stopColor="#9965CD" />
                        <stop offset="100%" stopColor="rgba(255,255,255,0)" />
                    </linearGradient>
                </defs>
            </svg>
            {
                isDataEmpty ?
                <div className="no-data__container">No data available</div> : 
                <ResponsiveLine
                data={props.chartData}
                colors={['url(#gradient1)', 'url(#gradient2)']}
                margin={{ top: 20, right: 50, bottom: 50, left: 50 }}
                enableArea={true}
                enableGridX={true}
                enableGridY={true}
                curve={"monotoneX"}
                tooltip={point => {
                    return <div className="tooltip__container">
                        <span className="tooltip__text">{point.point.data.y.toString()}</span>
                        <div className="tooltip-icon__container">
                            <TooltipIcon />
                        </div>
                    </div>;
                }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: 36,
                    legendPosition: 'middle',
                    truncateTickAt: 0,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: -40,
                    legendPosition: 'middle',
                    truncateTickAt: 0
                }}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointLabelYOffset={-12}
                enableTouchCrosshair={true}
                useMesh={true}
                yFormat=" >-.2f"
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: false,
                    reverse: false
                }}
                theme={{
                    text: {
                        fill: '#C1C2C6',
                        fontFamily: 'Satoshi-Medium'
                    },
                    grid: {
                        line: {
                            stroke: "#F6F8FB"
                        }
                    }
                }}
            />
            }
        </div>
    </div> 
};