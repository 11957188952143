const pdfMake = require('pdfmake/build/pdfmake');
const pdfFonts = require('pdfmake/build/vfs_fonts');

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function genPieSvg(redPercent, greenPercent) {

    // Convert percentages to angles
    const redAngle = (redPercent / 100) * 360;
    const greenAngle = (greenPercent / 100) * 360;
    const blueAngle = 360 - redAngle - greenAngle;

    // Define the colors for each segment
    const segments = [
        { angle: redAngle, color: '#E0818C' },   // Red segment
        { angle: blueAngle, color: '#ECDB71' }, // Green segment
        { angle: greenAngle, color: '#68C47A' }   // Blue segment
    ];

    // SVG dimensions and center point
    const width = 56;
    const height = 56;
    const cx = width / 2;
    const cy = height / 2;
    const outerRadius = 28;
    const innerRadius = 14; // Adjust the inner radius for the donut hole size

    // Helper function to convert polar coordinates to cartesian
    function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
        return {
            x: centerX + radius * Math.cos(angleInRadians),
            y: centerY + radius * Math.sin(angleInRadians)
        };
    }

    // Function to create a path for a donut segment
    function createDonutSegmentPath(startAngle, endAngle) {
        const startOuter = polarToCartesian(cx, cy, outerRadius, endAngle);
        const endOuter = polarToCartesian(cx, cy, outerRadius, startAngle);
        const startInner = polarToCartesian(cx, cy, innerRadius, startAngle);
        const endInner = polarToCartesian(cx, cy, innerRadius, endAngle);

        const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

        const pathData = [
            'M', startOuter.x, startOuter.y,
            'A', outerRadius, outerRadius, 0, largeArcFlag, 0, endOuter.x, endOuter.y,
            'L', startInner.x, startInner.y,
            'A', innerRadius, innerRadius, 0, largeArcFlag, 1, endInner.x, endInner.y,
            'Z'
        ].join(' ');

        return pathData;
    }

    // Generate paths for each segment
    let cumulativeAngle = 0;
    const paths = segments.map(segment => {
        const startAngle = cumulativeAngle;
        const endAngle = cumulativeAngle + segment.angle;
        const path = createDonutSegmentPath(startAngle, endAngle);
        cumulativeAngle = endAngle;
        return `<path d="${path}" fill="${segment.color}" stroke="white" stroke-width="2"/>`;
    });

    // Construct the SVG
    const svgContent = `
    <svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg">
      ${paths.join('\n')}
    </svg>
  `;

    return svgContent;
}


export function createScanReportPDF(text, aiPercent, humanPercent, deep=false) {
    const plotSvg = genPieSvg(aiPercent, humanPercent)
    const scannedText = text;

    function countWords(s){
        s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
        s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
        s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
        return s.split(' ').filter(function(str){return str!="";}).length;
    }

    const options = {
        rectWidth: 563,
        height: 842,
        detailsHeight: 94,
        scanResultHeight: 172,
        headerHeight: 72,
        breakdownHeight: 139,
        blocksGap: 8,
        borderColor: '#EDF1F9',
        borderRadius: 8,
    }
    if (!deep) {
        options.breakdownHeight = 0;
    }

    function cumulativeHeight(initialValue = 0) {
        let height = initialValue;
        return function (value = 0) {
            height += value
            return height
        }
    }

    const heightCalc = cumulativeHeight()
    const date = new Date()
    const rectOpt = {
        type: 'rect',
        x: 0,
        y: 0,
        w: options.rectWidth,
        r: options.borderRadius,
        color: "#FFFFFF",
        lineColor: options.borderColor,
    }

    const circle = (fill, stroke) => `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="6" r="6" fill="${fill}"/>
        <circle cx="6" cy="6" r="5.5" stroke="${stroke}" stroke-opacity="0.75"/>
    </svg>`

    const posHelper = (x = 0, y = 0) => {
        const pos = {x: x, y: y}
        return (shiftX = 0, shiftY = 0, set = false) => {
            const res = {...pos, x: pos.x + shiftX, y: pos.y + shiftY}
            if (set) {
                pos.x += shiftX
                pos.y += shiftY
            }
            return res
        }
    }
    const mixedPercent = 100 - (aiPercent + humanPercent);

    const resSign = {
        text: 'Mixed',
        color: "#6A4E17",
        fontSize: 16,
        bold: true,
        relativePosition: {x: -93 - 12 + 24, y: 10.5}
    }
    const resSignCanvas = {
        type: 'rect',
        x: 0,
        y: 0,
        w: 93,
        h: 38,
        r: options.borderRadius,
        color: "#F6DC99",
    }
    if(aiPercent > humanPercent && aiPercent > mixedPercent) {
        resSign.text = 'AI'
        resSign.color = "#86414a"
        resSignCanvas.color = "#F2D9D8"
        resSignCanvas.w = 65
        resSign.relativePosition.x += 28
    }
    if(humanPercent > aiPercent && humanPercent > mixedPercent) {
        resSign.text = 'Human'
        resSign.color = "#296e38"
        resSignCanvas.color = "#DAF2D8"
        resSign.relativePosition.x -= 3
    }

    let content = [
        {
            "columns": [
                {width: "50%", text: 'Scan report', style: 'header', margin: [16, 0, 0, 0]},
                {
                    width: "*",
                    text: 'Date: ' + date.toLocaleString(),
                    alignment: 'right',
                    margin: [0, 0, 0, 20],
                    color: "#A6A8B0",
                    fontSize: 12
                },

            ]
        },
        {
            margin: [0, 16, 0, 0],
            canvas: [
                {
                    ...rectOpt,
                    h: options.scanResultHeight + options.breakdownHeight,
                },
            ],
            absolutePosition: {x: 16, y: heightCalc(options.headerHeight)},
        },
        {
            stack: [
                {
                    margin: [24, 20, 0, 0],
                    text: 'Scan result', style: 'subheader'
                },
                {
                    margin: [24, 12, 0, 0],
                    "columns": [
                        {
                            width: "auto",
                            text: aiPercent + '% AI',
                            fontSize: 32,
                        },
                        {
                            width: "auto",
                            margin: [12, 0, 12, 2.5],
                            canvas: [
                                resSignCanvas,
                            ],
                        },
                        resSign
                    ]
                },
                {
                    margin: [24, 12],
                    style: 'text',
                    text: `Your text is a mix of AI-generated and human-written text resulting on ${aiPercent}% probability of being AI-generated.`,
                },
            ],
            relativePosition: {y: 16},
        },
    ]
    let start = posHelper(16, heightCalc(options.scanResultHeight))
    if ( deep )
        content = [...content,
            // PROBABILITY BREAKDOWN
            {
                absolutePosition: start(),
                svg: `<svg width="${options.rectWidth}" height="2">
      <line x1="0" y1="0" x2="${options.rectWidth}" y2="0" style="stroke:#EDF1F9;stroke-width:2" />
    </svg>`
            },
            {
                stack: [
                    {
                        margin: [20, 20, 0, 0],
                        text: 'Probability breakdown', style: 'subheader'
                    },
                    {
                        absolutePosition: start(24, 63, true),
                        stack: [
                            {
                                svg: plotSvg,
                            },
                            {
                                absolutePosition: start(76, 6, true),
                                stack: [
                                    {
                                        absolutePosition: start(0, 2),
                                        svg: circle('#F2D9D8', '#D96473')
                                    },
                                    {
                                        absolutePosition: start(18, 2),
                                        text: "AI-generated",
                                        style: "small",
                                    },
                                    {
                                        absolutePosition: start(0, 26),
                                        text: aiPercent + "%", style: 'digit',
                                    }
                                ],
                            },
                            {
                                absolutePosition: start(106, 0, true),
                                stack: [
                                    {
                                        absolutePosition: start(0, 2),
                                        svg: circle('#DAF2D8', '#42B45B')
                                    },
                                    {
                                        absolutePosition: start(18, 2),
                                        text: "Human-written",
                                        style: "small",
                                    },
                                    {
                                        absolutePosition: start(0, 26),
                                        text: humanPercent + "%", style: 'digit',
                                    }
                                ],
                            },
                            {
                                absolutePosition: start(106, 0, true),
                                stack: [
                                    {
                                        absolutePosition: start(0, 2),
                                        svg: circle('#F6DC99', '#D6C953')
                                    },
                                    {
                                        absolutePosition: start(18, 2),
                                        text: "Mixed",
                                        style: "small",
                                    },
                                    {
                                        absolutePosition: start(0, 26),
                                        text: (100 - aiPercent - humanPercent) + "%", style: 'digit',
                                    }
                                ],
                            }
                        ],
                    },
                ],
                absolutePosition: {x: 16, y: heightCalc()},
            },
        ]
    let textHeight = 54 + (text.length / 82 + (text.match(/\n/g) || []).length) * 17;
    content = [...content,
        // TEXT DETAILS
        {
            canvas: [
                {
                    ...rectOpt,
                    h: 94,
                },
            ],
            absolutePosition: {x: 16, y: heightCalc(options.breakdownHeight + options.blocksGap)},
        },
        {
            stack: [
                {
                    margin: [20, 20, 0, 0],
                    text: 'Text details', style: 'subheader'
                },
                {
                    margin: [20, 8],
                    columns: [
                        {
                            width: "33%",
                            text: [{text: 'Sentences count: ', style: "bold"}, text.split("\n").length], style: "text"
                        },
                        {
                            width: "33%",
                            text: [{text: 'Words count: ', style: "bold"}, countWords(text)], style: "text"
                        },
                        {
                            width: "33%",
                            text: [{text: 'Characters count: ', style: "bold"}, text.length], style: "text"
                        }
                    ],
                    style: 'text',
                },
            ],
            absolutePosition: {x: 16, y: heightCalc()},
        },
        // SCANNED TEXT
        {
            canvas: [
                {
                    ...rectOpt,
                    h: Math.min(textHeight, 330)
                },
            ],
            absolutePosition: {x: 16, y: heightCalc(options.detailsHeight + options.blocksGap)},
            // margin: [0, 8, 0, 0]
        },
        {
            stack: [

                {
                    margin: [20, 20, 0, 0],
                    text: 'Scanned text', style: 'subheader'
                },
                {
                    margin: [20, 8],
                    text: scannedText,
                    style: 'text',
                },
            ],
            absolutePosition: {x: 16, y: heightCalc()},
        }
    ]
    // textHeight -= 330
    // let pageNo = 1;
    // while (textHeight > 0) {
    //     content = [...content,
    //         { text: '', pageBreak: 'before' },
    //
    //         {
    //             canvas: [
    //                 {
    //                     ...rectOpt,
    //                     h: Math.min(options.height, textHeight)
    //                 },
    //             ],
    //             absolutePosition: {x: 16, y: 16},
    //         },
    //     ]
    //     console.log("add page")
    //     pageNo++;
    //     textHeight -= options.height - 20
    // }

    const docDefinition = {
        pageSize: {
            width: 595,
            height: 842
        },
        pageMargins: [16, 24],
        background: function (currentPage, pageSize) {
            console.log(pageSize)
            return {
                canvas: [
                    {
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: pageSize.width,
                        h: 842,
                        color: '#edeff1',
                    },
                ],
            }
        },
        content: content,
        styles: {
            header: {
                fontSize: 24,
                bold: true,
            },
            subheader: {
                fontSize: 20,
                bold: true,
            },
            text: {
                fontSize: 14,
                color: "#5C5E64"
            },
            bold: {
                bold: true,
                fontSize: 14,
                color: "#5C5E64"
            },
            digit: {
                fontSize: 18,
                color: '#080C1E',
            },
            small: {
                fontSize: 12,
                color: "#A6A8B0"
            }
        },
    };
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download("scan_report.pdf");
    // pdfDocGenerator.getBuffer((buffer) => {
    //     fs.writeFileSync('scan_report.pdf', buffer);
    // });
    // pdfMake.createPdf(docDefinition).getBlob(function (blob) {
    //
    //     // fs.writeFile('scan-report.pdf', blob);
    //     // fs.(blob, 'scan-report.pdf');
    // });
}

