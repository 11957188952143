import {createBrowserRouter} from 'react-router-dom'
import { Extension } from './pages/extension'
import { Main } from './pages/application/main'
import { APIUsage } from './pages/application/main/layout'
import { ExtensionLogin } from './pages/application/extensionLogin'


export const appRouter = createBrowserRouter([
    {
        path: '/',
        element: <Main />
    },
    {
        path: '/main',
        element: <Main />
    },
    {
        path: '/main/scan',
        element: <Main />
    },
    {
        path: '/main/britishizer',
        element: <Main />
    },
    {
        path: '/main/api',
        element: <Main />
    },
    {
        path: '/main/get-subscription',
        element: <Main />
    },
    {
        path: '/main/subs',
        element: <Main />
    },
    {
        path: '/main/api/table',
        element: <Main />
    },
    {
        path: '/main/api/docs',
        element: <Main />
    },
    {
        path: '/extension',
        element: <ExtensionLogin />
    }
])

export default appRouter;