import react from 'react'
import './style.css'
import { PencilIcon, TrashIcon } from '../icons'
import { CopyToClipboardIcon } from '../copyToClipboard';

export interface IRow{
    apikey: string,
    date_created: string,
    date_used: string,
    name: string;
    onDelete: () => void,
    onChange: () => void;
}

interface ITable {
    data: IRow[];
    onDelete: (key: string) => void,
    onChange: (key: string) => void;
}


const TableRow: react.FC<IRow> = (props) => {
    return <div className="table__row">
        <div className="row__cell">
            {props.name}
        </div>
        <div className="row__cell apikey__container">
            <span>{props.apikey}</span>
            <CopyToClipboardIcon textToCopy={props.apikey}/>
        </div>
        <div className="row__cell">
            {props.date_created}
        </div>
        <div className="row__cell">
            {props.date_used}
        </div>
        <div className="row__cell row__actions">
            <div className="icon__container" onClick={() => {
                props.onDelete()
            }}>
                <TrashIcon />
            </div>
            <div className="icon__container" onClick={() => {
                props.onChange()
            }}>
                <PencilIcon />
            </div>
        </div>
    </div>
}


export const Table: react.FC<ITable> = (props) => {
    return <div className='table'>
        <div className="table__header">
            <div className="header__cell">
                Name
            </div>
            <div className="header__cell">
                Key
            </div>
            <div className="header__cell">
                Created
            </div>
            <div className="header__cell">
                Last used
            </div>
            <div className="header__cell">
            </div>
        </div>
        <div className="table__content">
            {
                props.data.map((e) => {
                    return <TableRow 
                        {...e}
                        onChange={() => {props.onChange(e.apikey)}} 
                        onDelete={() => {props.onDelete(e.apikey)}}
                    />
                })
            }
        </div>
    </div>
}