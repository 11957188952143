import ReactGA from "react-ga4";

export const GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID
export const ENV_NAME = process.env.REACT_APP_ENV
export const PRIVY_ID = process.env.REACT_APP_PRIVY_ID
export const WORDS_GRANTED = 50000

ReactGA.initialize(GOOGLE_ID);

export const googleEvent = (event, options={}) => {
    ReactGA.event(event, options)
}

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN


console.log("BASE_URL", BASE_URL)
console.log("BACKEND_URL", BACKEND_URL)
console.log("MIXPANEL_TOKEN", MIXPANEL_TOKEN)