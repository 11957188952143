const levenshtein = require("js-levenshtein");
const Diff = require("diff");

export function splitTextIntoParagraphs(text) {
    // Split the text by two or more newlines (this identifies paragraphs)
    const paragraphs = text.split(/\n{1,}/);

    // Wrap each paragraph with <p> tags
    const wrappedParagraphs = paragraphs.map(para => `<p>${para.trim()}</p>`);

    // Join the paragraphs back together into a single string
    return wrappedParagraphs.join("\n");
}

export function colorize_diffs(text1, text2) {
    function isWord(token) {
        return /^[A-Za-z]+$/.test(token);
    }

    function tokenize(text) {
        return text.match(/[A-Za-z]+|[^A-Za-z]+/g) || [];
    }

    const tokens1 = tokenize(text1);
    const tokens2 = tokenize(text2);

    const isSame = (w1, w2) => {
        return levenshtein(w1, w2) <= 2
    }

    let diffs = Diff.diffArrays(tokens1, tokens2);
    let diffs2 = []

    diffs.forEach(part => {
        part.value.forEach(value => {
            diffs2.push({...part, value: value})
        })
    })
    diffs = diffs2;
    diffs2 = [];

    for (let i = 0; i < diffs.length; i++) {
        const curr = diffs[i];
        const next = diffs[i + 1]
        if (i < diffs.length - 1 &&
            curr.added !== next.added &&
            curr.removed !== next.removed &&
            isSame(curr.value, next.value)) {
            diffs2.push({source: curr.value, target: next.value, added: false, modified: true, removed: false})
            i += 1;
        } else {
            diffs2.push(curr)
        }
    }

    diffs = diffs2;
    let result1 = [];
    let result2 = [];

    diffs.forEach(part => {
        if (part.added) {
            if (isWord(part.value)) {
                result2.push(`<span class="word">${part.value}</span>`);
            } else {
                result2.push(part.value);
            }
        } else if (part.removed) {
            if (isWord(part.value)) {
                result1.push(`<span class="word">${part.value}</span>`);
            } else {
                result1.push(part.value);
            }
        } else {
            if (!!part.source) {
                const charDiffs = Diff.diffChars(part.source, part.target);

                let wordResult1 = '';
                let wordResult2 = '';

                charDiffs.forEach(charPart => {
                    if (charPart.added) {
                        wordResult2 += `<span class="syms">${charPart.value}</span>`;
                    } else if (charPart.removed) {
                        wordResult1 += `<span class="syms">${charPart.value}</span>`;
                    } else {
                        wordResult1 += charPart.value;
                        wordResult2 += charPart.value;
                    }
                });

                result1.push(`<span class="word">${wordResult1}</span>`);
                result2.push(`<span class="word">${wordResult2}</span>`);
            } else {
                result1.push(part.value);
                result2.push(part.value);
            }

        }
    });

    return [splitTextIntoParagraphs(result1.join('')), splitTextIntoParagraphs(result2.join(''))];
}
