import react, { useState } from 'react'
import { ArrowIcon } from '../icons'
import './style.css'

interface IOption{
    title: string;
    setActive: (state: boolean) => void;
}
export const Option: react.FC<IOption> = (props) => {
    return <div className='option' onClick={() => {
        props.setActive(false);
    }}>{props.title}</div>
}


interface ISelector {
    onSelect: (trashold: number) => void;
}

export const Selector: react.FC<ISelector> = (props) => {

    const [active, setActive] = useState(false);
    const [activeOption, setActiveOption] = useState('7 days');

    const options = [
        "7 days",
        "14 days",
        "30 days",
        "90 days",
        "360 days"
    ];

    const optionsToTrashold = {
        "7 days": 7,
        "14 days": 14,
        "30 days": 30,
        "90 days": 90,
        "360 days": 360
    }

    return <div className={'selector__container ' + (active ? 'selector__container-active' : '')}>
        <div className="active-selector__container" onClick={() => {
            setActive(!active);
        }}>
            <span>{activeOption}</span>
            <div className={"arrow__icon " + (active ? "arrow__icon-active" : "")}>
                <ArrowIcon />
            </div>
        </div>
        <div className={"options " + (active ? "options__active" : "")}>
            {
                options.map(e => <Option 
                        title={e} 
                        setActive={(state: boolean) => {
                            setActive(state);
                            setActiveOption(e);
                            props.onSelect((optionsToTrashold as any)[e]!)
                        }}/>
                    )
            }
        </div>
    </div>
}