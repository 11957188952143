import react, { useEffect, useState } from 'react'
import { ProgressBar } from '../progressBar'
import './style.css'
import { getWordsUsed } from '../../requests';
import { useNavigate } from 'react-router-dom';
import {logEvent} from "../../utils";
import { PremiumStatisticCircle } from '../../pages/application/main/PremiumPage';
import { BritishIcon, ScanStatsIcon } from '../icons';

export const formatBigNumbers = (number: string) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const CurrentPlan: react.FC = () => {
    const [wordsUsedDeepScan, setWordsUsedDeepScan] = useState(0);
    const [wordsUsedSimpleScan, setWordsUsedSimpleScan] = useState(0);
    const [grantedDeepScan, setGrantedDeepScan] = useState(0);
    const [grantedSimpleScan, setGrantedSimpleScan] = useState(0);
    const [usedBritishWords, setUsedBritishWords] = useState(0);
    const [grantedBritishWords, setGrantedBritishWords] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        getWordsUsed().then((e) => {
            setGrantedDeepScan(e.granted_words_deep_scan);
            setGrantedSimpleScan(e.granted_words_simple_scan);
            setGrantedBritishWords(e.granted_british_words);
            setWordsUsedDeepScan(e.used_words_deep_scan);
            setWordsUsedSimpleScan(e.used_words_simple_scan);
            setUsedBritishWords(e.used_british_words);
        })
    }, [])

    return <div className='current-plan__container'>
        <div className="current-plan__content">
        <PremiumStatisticCircle
            wordsUsedSimple={wordsUsedSimpleScan + wordsUsedDeepScan}
            wordsUsedDeep={wordsUsedDeepScan}
            grantedSimple={grantedSimpleScan}
            grantedDeep={grantedDeepScan}
            header={<div className='premium__header-container'>
                <ScanStatsIcon />
                <span>Scan words used</span>
            </div>}
        />
        <PremiumStatisticCircle
            wordsUsedSimple={usedBritishWords}
            grantedSimple={grantedBritishWords}
            header={<div className='premium__header-container'>
                <BritishIcon />
                <span>Britishizer words used</span>
            </div>}
        />
        </div>
        <button className='primary' onClick={() => {
            navigate('/main/get-subscription');
            logEvent('Get More Words');
        }}>Get More Words</button>
    </div>
}