import react, { useState } from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CheckIcon, CopyIcon } from '../icons';



interface ICopyToClipboardICon{
    textToCopy: string;
}

export const CopyToClipboardIcon: react.FC<ICopyToClipboardICon> = (props) => {
    const [copied, setCopied] = useState(false);
    return <CopyToClipboard 
        text={props.textToCopy} 
        onCopy={() => {
            setCopied(true);
        }}
    >
        <div>
        {
            copied ? <CheckIcon /> : <CopyIcon />
        }
        </div>
    </CopyToClipboard>
}