import react, { useEffect, useRef, useState } from 'react'
import './style.css'

interface IProgressBar {
    progress: number;
    width?: number;
}

export const getColor = (progress: number) => {
    var color = '#48B266';
    if (progress >= 50) {
        color = '#E5C87C';
    }
    if (progress >= 86 || Number.isNaN(progress)) {
        color = '#D96473'
    }
    if (progress == -1) {
        color = '#C1C2C6';
    }
    return color;
}

export const getInlineColor = (progress: number) => {
    var color = '#f2f9f2';
    if (progress > 50) {
        color = '#F9EDED'
    }
    return color;
}
export const getInlineBackgroundColor = (progress: number) => {
    var color = '#48b266';
    if (progress > 50) {
        color = '#D96473'
    }
    return color;
}

export const ProgressBar: react.FC<IProgressBar> = (props) => {
    const {progress} = props;
    const containerRef = useRef<HTMLElement>(null);
    const [width, setWidth] = useState(props.width ? props.width : 0);

    var color = getColor(progress);
    useEffect(() => {
        if (!width) {
            setTimeout(() => {
                try {
                    setWidth(containerRef.current!.getBoundingClientRect().width);
                } catch {
                    setWidth(0);
                }
            }, 400);
        }
    }, [containerRef])
    return <div className='progress-bar__container' ref={containerRef as any}>
        <div className="progress-bar__content" style={{
            width: `${width * progress / 100}px`,
            backgroundColor: color
        }}>
        </div>
    </div>
}